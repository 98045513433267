<template>
  <section class="col w-full mt-10" id="newsletter">
    <header class="text-right">
      <p class="text-c_gold">Número 16, diciembre 2024 - enero 2025</p>
    </header>
    <article>
      <h2>¡Tanto por aprender!</h2>
      <p class="text-c_lightmagenta mb-4">Editorial / Guadalupe Vadillo</p>
      <p>
        Casi todos, con la llegada del fin de año hacemos un balance de lo
        aprendido en ese periodo y, al iniciar un nuevo ciclo, nos proponemos
        incrementar nuestra base de conocimientos y habilidades. Esto ha sido
        especialmente el caso de quienes asistimos a la 2ª Jornada COIL UNAM
        2024, evento que nos mostró, en apenas dos días, una variedad de aristas
        y potencialidades de estas experiencias de aprendizaje. Al enriquecernos
        con las perspectivas de docentes, estudiantes y coordinadores COIL de
        nuestra Universidad, surgieron nuevas avenidas de conocimiento que
        podemos explorar tanto en la literatura especializada como a través de
        procesos formativos nacionales e internacionales, y del intercambio que
        surge con el creciente número de COILers UNAM.
      </p>
      <p>
        En este número presentamos la reseña de la Jornada que evidencia la
        diversidad de perspectivas y formatos de nuestros COIL. También
        acercamos a nuestros lectores a un tema muy poco frecuente: el uso de la
        risa y la jovialidad como fórmula para reducir el estrés y promover
        mayor cohesión entre estudiantes y docentes de los países involucrados
        en una experiencia internacional y en línea de aprendizaje. Ilustramos
        sus ventajas a través de una colección de testimonios de estudiantes y
        docentes y compartimos un evento formativo en línea próximo a
        realizarse.
      </p>
      <p>
        Cerramos este COILaborando con una definición de COIL que nos dará mucho
        qué pensar.
      </p>
      <p class="mt-3">¡Esperamos que disfrute con este número!</p>
    </article>
    <article>
      <h2>2ª Jornada COIL UNAM 2024</h2>
      <p class="text-c_lightmagenta mb-4">
        Reseña del evento / Eréndira Peniche y Erik Granados
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/review.png"
          alt="Reseña del evento"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        Eran las 7 de la mañana del martes 26 de noviembre y en la ENES Mérida
        la expectativa por el inicio de la 2da. Jornada COIL UNAM 2024 se hacía
        más palpable cada segundo. Bajo acogedores 24°C de una fresca mañana en
        Mérida se ultimaban detalles para recibir a un centenar de docentes y
        funcionarios de escuelas, facultades, centros, e institutos de nuestra
        Universidad, así como de la Universidad Autónoma de Yucatán (UADY).
      </p>
      <p>
        Un par de horas después, el Edificio de Docencia de la ENES Mérida lucía
        concurrido con personas saludándose, tomando café, y apresurándose a
        reservar un buen lugar en el Aula 105 para la inauguración del evento y
        las actividades del día. En punto de las 10:00 hrs. nuestras autoridades
        y personas invitadas especiales, encabezadas por la Dra. Tamara
        Martínez, dieron el banderazo de salida para el inicio de los trabajos
        destacando la relevancia que la internacionalización tiene en la mejora
        de la educación superior en un momento de la historia donde se debe
        hacer "más con menos". Con un auditorio a reventar y el entusiasmo del
        goya con la audiencia de pie comenzamos a escribir las primeras letras
        de la Jornada.
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/openning.jpg"
          alt="Inauguración de la 2da. Jornada COIL UNAM 2024 (ENES Mérida)"
          class="mx-auto max-w-lg block"
        />
        <span class="text-center block my-2 text-c_2a_orange italic"
          >Inauguración de la 2da. Jornada COIL UNAM 2024 (ENES Mérida)</span
        >
      </p>
      <p>
        Acto seguido, la voz de Carlos Maza de la DGECI presentaba a las
        audiencias a la Dra. Stephanie Doscher (Universidad de Minnesota),
        ponente magistral de ese día y una de las principales aliadas de la UNAM
        en el impulso de la metodología. Con la analogía de que todas y todos
        conformamos lo que se conoce como el planeta Tierra, la Dra. Doscher
        abrió su participación enfatizando la necesidad de conectarnos y
        compartir conocimiento para resolver los problemas que nos son comunes.
        Para ella, la metodología COIL es una manera de intercambiar
        conocimiento y colaborar entre docentes y alumnado. Destacó el papel
        clave de quienes los coordinan para facilitar el proceso de
        capacitación, identificación de pares académicos, seguimiento y
        mantenimiento de la estrategia COIL, así como para "viralizar" esta
        experiencia.
      </p>
      <p>
        Sería negligente hablar de COIL sin conocer ejemplos concretos de su
        ejecución. Por esto la presentación de la "Mesa de Diálogo sobre
        Experiencias COIL Exitosas", que tuve el privilegio de coordinar,
        prometía brindar tips, estrategias, consejos, y aprendizajes de quienes
        han incursionado destacadamente en esta metodología. De la mano de la
        Dra. Ingrid Cabrera (F. de Psicología, UNAM) conocimos que es esencial
        comunicar nuestro entendimiento sobre qué significa "colaborar", definir
        claramente las aportaciones de cada institución par, y monitorear el
        sentir del alumnado participante como retroalimentación y evaluación del
        proceso. Del Dr. Eduardo Espinosa (F. de Ingeniería, UNAM) aprendimos
        cómo la comida es una deliciosa forma de romper el hielo, y cómo estos
        cursos abonan al desarrollo profesional mediante la publicación
        académica de los resultados de la experiencia. Con el Dr. Noé González
        (F. de Filosofía, UNAM) descubrimos los retos de conjuntar modalidades
        educativas distintas y la necesidad de promover la empatía cuando la
        edad de los estudiantes del curso COIL es muy variable. En su
        intervención, el Mtro. Omar Verdeja (F. de Economía, UADY) recalcó que
        mantener el respeto en la comunicación es fundamental durante estos
        cursos, así como el reconocimiento mutuo de las normas de cada
        institución. Para concluir la actividad con broche de oro, el Mtro.
        Alejandro Herrerías (FAD, UNAM) demostró que una adecuada logística y
        entendimiento entre pares resulta en productos impresionantes generados
        interdisciplinariamente, y ¡hasta en amistades!
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/participants.jpg"
          alt="Participantes en la Mesa de Diálogo Experiencias COIL Exitosas"
          class="mx-auto max-w-lg block"
        />
        <span class="text-center block my-2 text-c_2a_orange italic"
          >Participantes en la Mesa de Diálogo Experiencias COIL Exitosas</span
        >
      </p>
      <p>
        Tras un breve almuerzo de antojitos yucatecos las personas participantes
        se dividieron en uno de los tres talleres simultáneos de acuerdo con sus
        intereses y formación ya adquirida: un primer acercamiento a la
        metodología COIL a cargo de Carlos Maza (DGECI) y Erik Granados (ENES
        Mérida), y un taller de consolidación institucional de un programa COIL
        de la mano del experto ponente y tallerista internacional Osvaldo Succi
        Jr. (Brasil). Para las personas participantes en línea se brindó una
        capacitación para el diseño de un proyecto colaborativo, cuyo
        responsable fue el Prof. Jorge Matamoros (Honduras) bajo la coordinación
        de la Dra. Guadalupe Vadillo (CUAED).
      </p>
      <p>
        El taller introductorio inició con una exploración extendida de lo que
        es (y no es) COIL, lo cual dio la pauta para que las personas
        participantes expresaran sus dudas más apremiantes sobre la metodología.
        Algunas preguntas recurrentes que surgieron son:
        <em
          >¿Cuántas semanas debe durar el módulo?, ¿Pueden ser más de dos
          instituciones?, ¿Cómo se evalúa al alumnado?, ¿Quién decide las
          herramientas tecnológicas a utilizar?</em
        >
        La dinámica reflexiva del taller permitió el intercambio de ideas sobre
        los contextos de cada participante y facilitó la elaboración de un plan
        para implementar un COIL con el impulso de la familia COIL UNAM. El
        taller concluyó tras una sensibilización sobre el propio perfil
        académico y estilo de trabajo de cada participante, así como el
        reconocimiento de esa misma información como un eje vital para
        identificar un buen par académico para colaborar en esta experiencia.
      </p>
      <p>
        Por su parte, el Dr. Osvaldo Succi impartió el taller presencial
        titulado: "Consolidación institucional de un programa COIL". En él se
        subrayaron las posibilidades no tradicionales que ya se empiezan a
        manejar en los COIL, por ejemplo, la participación de organizaciones de
        la sociedad civil y de empresas para que el estudiante tenga una
        experiencia auténtica y que lo vincula con la sociedad desde sus cursos
        universitarios.
      </p>
      <p>
        Para los participantes remotos de la Jornada el Mtro. Jorge Matamoros
        presentó el taller “Diseño del proyecto colaborativo”. Los participantes
        del taller se nutrieron de la amplísima experiencia y conocimiento del
        Mtro. Matamoros y conocieron, a través de ejemplos, diversas
        posibilidades que dan los proyectos en equipos binacionales de
        estudiantes, así como los impactos positivos que tienen en su formación.
      </p>
      <p>
        Al término de los talleres la mayoría de las personas participantes se
        apresuraron a tomar el autobús de regreso a su hotel. Después de las
        18:00 hrs. el Edificio de Docencia estaba irreconocible sin la energía
        de las actividades acaecidas durante el día, pero en el aire permanecían
        los aprendizajes derivados de un evento sin precedentes en la historia
        de la internacionalización en la ENES Mérida.
      </p>
      <p>
        Al día siguiente el Centro Peninsular en Humanidades y Ciencias Sociales
        recibió a los participantes de dicho evento: representantes de
        internacionalización de facultades, escuelas, centros e institutos de la
        UNAM, así como profesores de la Universidad Autónoma de Yucatán. Para
        empezar el día, se presentaron los testimonios, en video, de alumnos que
        han participado en cursos COIL, quienes contagiaron a la audiencia con
        su entusiasmo, destacando que para ellos fue una experiencia muy
        importante pues les permitió trabajar de manera colaborativa con
        estudiantes de otras universidades de diferentes países, reconociendo
        que la experiencia será importante para su desempeño como futuros
        profesionistas y manifestado su deseo de que cada vez se abran más
        opciones COIL en la universidad.
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/coilers-students.jpg"
          alt="alumnos que han participado en cursos COIL"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        En su conferencia magistral, el Dr. Osvaldo Succi, académico del Centro
        Paula Souza, de São Paulo, Brasil compartió todas las ventajas que la
        Inteligencia Artificial tiene no solo para la organización de cursos
        COIL, sino también como herramienta para la investigación de este modelo
        de enseñanza aprendizaje.
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/osvaldo-succi.jpg"
          alt="Dr. Osvaldo Succi, académico del Centro Paula Souza"
          class="mx-auto max-w-lg block"
        />
        <span class="text-center block my-2 text-c_2a_orange italic"
          >Dr. Osvaldo Succi, académico del Centro Paula Souza</span
        >
      </p>
      <p>
        La Mesa de diálogo Buenas prácticas de gestión y coordinación COIL, fue
        un espacio muy dinámico en el que expertas en gestión de cursos COIL de
        nuestra Universidad, y de la UADY compartieron sus recomendaciones la
        gestión de estos cursos:
      </p>
      <ul>
        <li>Contar con el respaldo de las autoridades de la institución.</li>
        <li>Inclusión de cursos COIL en los planes de estudio.</li>
        <li>Reconocimiento a los académicos que implementen cursos COIL.</li>
        <li>Ser el puente entre autoridades, profesores y estudiantes.</li>
        <li>
          Aprovechar el contacto que los académicos de las entidades ya tienen
          con pares internacionales.
        </li>
        <li>
          Acercar a los profesores materiales COIL tales como una guía general,
          tutoriales y ofrecer sesiones de acompañamiento.
        </li>
        <li>
          Entre las características personales con las que es deseable contar
          destacan: comunicación asertiva, perseverancia, voluntad, creatividad,
          innovación, formación continua, organización.
        </li>
        <li>
          Optar por las tecnologías y procesos más simples y fáciles de usar.
        </li>
      </ul>
      <p>
        Por la tarde, los asistentes tuvieron la oportunidad de participar en
        talleres que se impartieron de manera simultánea, uno para
        principiantes, que estuvo a cargo de la Dra. Dra. Guadalupe Vadillo y la
        Mtra. Teresa González de la Coordinación de Universidad Abierta y
        Educación Digital, UNAM, en el que, a través de un cuadernillo de
        trabajo, cada participante pudo trabajar en el diseño de la estructura
        de un COIL. Para ello se explicó qué es un COIL, se trabajó en la ficha
        técnica del curso, los recursos de aprendizaje, el perfil de egreso, los
        objetivos del curso y algunos tips para llegar a acuerdos con los pares
        internacionales.
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/cuadernillo.png"
          alt="diseño de la estructura de un COIL"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        El segundo taller, Vinculación de COIL con los Objetivos del Desarrollo
        Sostenible, fue impartido por la Dra. Stephanie Doscher y Mtra. Gabriela
        Méndez de la Universidad de Minnesota, quienes, a través de trabajo en
        binas y luego de una actividad rompehielo, ayudaron a los asistentes a
        diseñar un COIL tomando en cuenta los ODS. Se revisó el texto
        <em
          >Educación para los Objetivos de Desarrollo Sostenible: objetivos de
          aprendizaje para definir los objetivos de aprendizaje del COIL</em
        >, las actividades a realizar y se plantearon incluso estrategias de
        evaluación, así las talleristas lograron que los asistentes vivieran la
        experiencia de diseño e implementación de un COIL, descubriendo muchas
        maneras creativas para que se logren los objetivos de aprendizaje
        incluso si los profesores del COIL son de materias /disciplinas
        distintas.
      </p>
      <p class="my-6">
        <img
          src="@/assets/images/coilaborando/2024/diciembre/ods.png"
          alt="Educación para los Objetivos de Desarrollo Sostenible"
          class="mx-auto max-w-lg block"
        />
      </p>
      <div class="m-4 p-4 bg-cyan-100 rounded-sm">
        <h3 class="!mt-0">Numeralia de la Jornada</h3>
        <p>
          Además de la presencia y participación de las autoridades de la UNAM y
          la UADY, y de los 20 ponentes en las distintas actividades (cuatro
          invitados internacionales), la audiencia de nuestra Jornada estuvo
          conformada por: 112 personas registradas presencialmente totales, 25%
          de la UADY, 156 registros remotos.
        </p>
        <p>
          Primer día online: 22 conectados en tiempo real y 857 reproducciones,
          desde México, EUA, RU, Ecuador, Colombia, Perú, Argentina, Brasil,
          España y Dinamarca. Segundo día online: 26 conectados en tiempo real,
          y 1,707 reproducciones hasta ahora, con nuevos países en la lista:
          Francia, Chile, Puerto Rico y Uruguay.
        </p>
        <p class="text-right">(Datos de Carlos Maza)</p>
      </div>
      <p>
        Luego de una reflexión final se procedió a la clausura en el que las
        autoridades de la UNAM y la UADY invitaron a los asistentes a la
        Jornada, tanto en modalidad presencial como a distancia, a aventurarse a
        organizar COIL que le permitan a los estudiantes experimentar el mundo
        sin salir de casa.
      </p>
    </article>
    <article>
      <h2>Risa y COIL</h2>
      <p class="text-c_lightmagenta mb-4">Reseña de un artículo</p>
      <h3>La importancia de la risa en los COIL</h3>
      <p>
        Prácticamente todos los estudios que se publican en revistas académicas
        sobre COIL se refieren a su diseño y a la adquisición de contenido. En
        las formaciones docentes, se tiende a enfatizar la estructuración del
        contenido, aspectos de la co-docencia y el manejo de las actividades de
        aprendizaje. En este artículo de L.A. Dewey, de la Universidad
        Washington y Lee, y de Atiah Abdullah Sidek, de la Universidad
        Internacional Islámica de Malasia, se aborda el humor, una variable muy
        poco estudiada en la promoción de dos objetivos fundamentales de los
        COIL,
        <strong
          >el aprendizaje transcultural y la generación de cohesión
          grupal</strong
        >. El propósito de los autores es que se le considere como una
        herramienta pedagógica para el logro de dichos objetivos, así como para
        la reducción del estrés y la promoción de interacciones auténticas que
        mejoren la comprensión intercultural. Esto es particularmente importante
        en tanto que este tipo de experiencias de aprendizaje exige que los
        alumnos se contacten y sean productivos con estudiantes que les son
        extraños y que, además, viven en otro país.
      </p>
      <p>
        Debido a que la risa es inherentemente social, autores como Jung en 2003
        señalan que facilita la colaboración interpersonal. La jovialidad busca
        priorizar el involucramiento sobre las consecuencias externas y se
        considera un medio para promover la flexibilidad frente a las
        complejidades de la tecnología, diferencias horarias, barreras de
        lenguaje, entre otros factores que se enfrentan con frecuencia en los
        COIL.
      </p>
      <p>
        Los autores, quienes fueron instructores de la experiencia reseñada, se
        unieron a la iniciativa de una fundación de Estados Unidos que inició un
        programa COIL para conectar a universidades norteamericanas con las de
        países con mayoría musulmana. Dichos instructores tenían diferencias
        religiosas, de edad, de primer idioma y de área disciplinaria. Además,
        uno pertenece a una universidad del sudeste asiático y trabaja en
        ingeniería, mientras el otro labora en una pequeña institución y enseña
        comunicación.
      </p>
      <p>
        El COIL tuvo una duración de cuatro semanas, con sesiones semanales
        síncronas de 75 minutos. Tuvieron una primera experiencia e hicieron una
        evaluación para realizar un rediseño para la segunda vez que se ofreció
        el curso. En un principio, la actividad rompehielo consistió en
        identificar seis similitudes y seis diferencias sobre sus universidades
        y sus programas. Se dieron cuenta que no llevaba a una comunicación
        eficiente entre pares internacionales ni reducía su estrés o
        preocupación. En la siguiente ocasión se reconfiguró la actividad para
        que fuera una competencia de cuestionarios durante la segunda sesión
        síncrona. Agregaron preguntas sobre los respectivos países y ya no
        pidieron que se hiciera una búsqueda en internet, sino que se
        cuestionaran entre ellos al trabajar en pequeños equipos en salas de
        Zoom. Lo más importante fue la adición de dos aspectos: la toma de una
        foto grupal con caras graciosas y la grabación de un trabalenguas en el
        idioma natal de cada participante (a partir de un modelo
        propositivamente imperfecto). La idea era evocar una reacción de risa en
        los integrantes del grupo y bajar la ansiedad que les pudiera provocar
        el trabajo con participantes de otro país.
      </p>
      <p>
        En la siguiente sesión se presentó a los ganadores en tres categorías:
        la foto más creativa o con las caras más graciosas, la mejor grabación
        de trabalenguas en ambos idiomas y el grupo ganador en ambas áreas.
      </p>
      <p>
        Ambos docentes registraron observaciones no participativas en las
        sesiones síncronas y a través de la retroalimentación de los
        estudiantes. En las sesiones se observó y registró el nivel de
        involucramiento, y la frecuencia de dinámicas comunicativas y
        colaboración. Al solicitar directamente a los aprendices su
        retroalimentación, fue posible contar con información relevante de sus
        experiencias, percepciones y sugerencias de mejora. Esta actividad
        permitió tener información sobre sus patrones de comunicación, niveles
        de involucramiento, conducta colaborativa y claves no verbales de los
        estudiantes.
      </p>
      <p>
        En sesiones semanales de trabajo ambos docentes compartieron sus
        observaciones y los puntos de vista de los estudiantes. En la primera
        implementación del COIL, notaron que los estudiantes tendían a ser más
        serios durante sus intervenciones, casi no charlaban casualmente y en
        sus interacciones había períodos de silencio. El diálogo se limitaba a
        la tarea. En cambio, en la segunda ocasión cambió la dinámica: los
        estudiantes tenían conversaciones más relajadas y abiertas que iban más
        allá de los temas de clase y en las que compartían anécdotas personales
        e insights culturales. La retroalimentación recibida en el segundo año
        fue muy positiva y su favorita fue la actividad del cuestionario antes
        descrita. En el tercer año se repitió la experiencia y nuevamente los
        resultados fueron muy satisfactorios.
      </p>
      <p class="apa">
        Dewey, L. A. y Abdullah Sidek, A. (2024). The laughter effect: Enhancing
        cross-cultural learning and cohesiveness in a virtual environment.
        <em>Journal of Virtual Exchange</em>, 7(SI-IVEC2023), 1-15,
        <a href="https://doi.org/10.21827/jve.7.41429" target="_blank"
          >https://doi.org/10.21827/jve.7.41429</a
        >
      </p>
    </article>
    <article>
      <h2>Próximos eventos formativos</h2>
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <div>
          <p>
            La Dra. Stephanie Doscher, ahora desde la Universidad de Minnesota,
            presenta el taller de primavera 2025. Para mayor información,
            complete
            <a
              href="https://shorturl.at/QQmOk"
              target="_blank"
              rel="noopener noreferrer"
              >este formulario</a
            >.
          </p>
          <p class="my-6">
            <a
              href="https://shorturl.at/QQmOk"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                src="@/assets/images/coilaborando/2024/diciembre/coil-design.png"
                alt="taller de primavera 2025"
                class="mx-auto block"
            /></a>
          </p>
        </div>
        <div>
          <p class="my-6">
            <img
              src="@/assets/images/coilaborando/2024/diciembre/doscher.png"
              alt="Dra. Stephanie Doscher"
              class="mx-auto max-w-lg block"
            />
          </p>
        </div>
      </div>
    </article>
    <article>
      <h2>Testimonios</h2>
      <p>
        En esta ocasión presentamos un conjunto de testimonios de estudiantes
        COILers de la UNAM y de algunos pares académicos extranjeros para
        mostrar la riqueza que estas experiencias internacionales de aprendizaje
        aportan a cada individuo.
      </p>
      <p class="my-6">
        <a
          href="https://youtu.be/_KVLW5uoSyg"
          target="_blank"
          rel="noopener noreferrer"
          ><img
            src="@/assets/images/coilaborando/2024/diciembre/testimonios.jpg"
            alt="taller de primavera 2025"
            class="mx-auto max-w-lg block"
        /></a>
      </p>
    </article>
    <article>
      <h2>Para reflexionar…</h2>
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <div>
          <p>
            COIL no es solo un método educativo. Es una filosofía que entiende
            que el conocimiento no tiene fronteras, que la diversidad es nuestra
            mayor riqueza, y que cada estudiante tiene una historia única que
            contar.
          </p>
          <p>
            Cada proyecto es una oportunidad de tejer conexiones globales, de
            romper muros físicos y mentales y construir puentes. No se trata
            solo de aprender sobre otras culturas, sino de aprender con otras
            culturas.
          </p>
          <p class="italic"><strong>Caridad Rangel</strong></p>
        </div>
        <div>
          <p class="my-6">
            <img
              src="@/assets/images/coilaborando/2024/diciembre/caridad-rangel.jpg"
              alt="Caridad Rangel"
              class="mx-auto max-w-lg block"
            />
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
h2 {
  margin: 0;
}
#newsletter p {
  @apply leading-relaxed mb-2;
}
#newsletter ul {
  @apply my-3;
}
#newsletter ul li {
  @apply text-xs list-disc list-inside mb-1;
}
#newsletter .apa {
  @apply ml-5 leading-6 mb-6 text-c_darkyellow;
  text-indent: -1.25rem;
}
</style>

<script>
export default {
  name: "DiciembreView",
  components: {},
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/diciembre",
    };
  },
};
</script>
