<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/agosto/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 15, octubre - noviembre de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <h3>
              Visita del Dr. Osvaldo Succi Junior a entidades académicas de la
              UNAM
            </h3>
            <h4 class="text-right text-sm text-c_darkyellow">
              por Mitzi Gómez y Carlos Maza
            </h4>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/octubre/editorial-Succi.jpg"
                alt="Visita del Dr. Osvaldo Succi Junior a entidades académicas de la UNAM"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              Como parte de la agenda desarrollada alrededor de la Segunda
              Jornada COIL UNAM 2024, que se llevó a cabo los días 26 y 27 de
              noviembre en la ciudad de Mérida, Yucatán, y cuyos pormenores
              relataremos en el próximo número de nuestro boletín COILaborando,
              tuvimos el gusto de recibir al Dr. Osvaldo Succi Junior,
              coordinador de los proyectos COIL del Centro Paula Souza del
              estado de São Paulo, Brasil, y a la Dra. Stephanie Doscher,
              vicedecana para la internacionalización del currículo en la
              Universidad de Minnesota, acompañada de la Mtra. Gabi Méndez de
              esa misma institución.
            </p>
            <p>
              Nuestros invitados extranjeros llegaron a la ciudad de Mérida el
              domingo 22 de noviembre, para asistir el lunes 23, a las 10:00 am,
              a una reunión con funcionarios y docentes de la Escuela Nacional
              de Estudios Superiores Unidad Mérida. La conversación giró en
              torno de las oportunidades de desarrollo de la
              internacionalización académica mediante el uso de las estrategias
              de Aprendizaje Colaborativo Internacional en Línea. Como
              introducción a lo que habría de venir durante el evento principal,
              representantes de la Secretaría Académica y de los diferentes
              programas de estudios de la ENES Mérida pudieron plantear a
              nuestros invitados especiales sus inquietudes al respecto de esta
              metodología. Todos manifestaron un gran interés y disposición a
              emprender la aventura COIL
            </p>
            <p>
              Más tarde el Dr. Francisco Xavier Chiappa Carrara, director de la
              ENES recibió al Dr. Succi y a la Dra. Doscher junto a los
              coordinadores de la Segunda Jornada COIL UNAM 2024 en sus
              oficinas, donde tuvimos oportunidad de profundizar el
              planteamiento de perspectivas y oportunidades abiertas a la planta
              docente de la ENES mediante la internacionalización sin salir de
              casa.
            </p>
            <p>
              Por la tarde del lunes 25 visitamos con nuestros invitados las
              instalaciones del Centro Peninsular en Humanidades y Ciencias
              Sociales (CEPHCIS), segunda sede de la Segunda Jornada COIL, donde
              fuimos recibidos por su directora, la Dra. María Carolina
              Depetris, quien guió una visita por las instalaciones de lo que
              fuera un hospital ferrocarrilero a principios del siglo XX. En
              esta reunión tuvimos oportunidad de evaluar las posibilidades de
              vinculación internacional por medio de COIL para investigadores en
              entidades como son los Centros e Institutos de la UNAM, vinculados
              con la docencia por diferentes medios.
            </p>
            <p>
              Estas visitas permitieron a nuestros invitados internacionales
              obtener una visión de conjunto sobre lo que se hace en estas sedes
              de la UNAM en Mérida, de modo que al iniciarse la Jornada, ya se
              sentían “como en casa”.
            </p>
            <p>
              Al término de la Jornada, el Dr. Osvaldo Succi nos acompañó a la
              ciudad de México, donde tuvimos oportunidad, el jueves 28, de
              llevarlo a conocer la FES Zaragoza y donde se reunió con docentes
              de esa entidad académica que también mostraron una gran curiosidad
              por las metodologías COIL. El viernes 29 lo llevamos a pasear por
              Ciudad Universitaria, donde fue recibido por otro grupo de
              docentes y funcionarios, esta vez del Sistema de Universidad
              Abierta y Educación a Distancia (SUAyED), en las instalaciones de
              Coordinación de Universidad Abierta y Educación Digital (CUAED).
              Su presentación se centró en las posibilidades de apoyo de la
              inteligencia artificial generativa en los procesos de diseño de
              COIL y de sus proyectos colaborativos.
            </p>
            <p>
              La colaboración de la UNAM con el Centro Paula Souza y con la
              Universidad de Minnesota se fortalecen gracias a esta intensa
              visita, en la que los doctores Succi y Doscher pudieron conocer
              mejor todo aquello que hace grande a la UNAM.
            </p>
          </article>
          <article>
            <h2>Reseña de documentos</h2>
            <h3>
              <em
                >How to Choose and Use Technology Tools for COIL with Data
                Privacy and Data Protection in Mind</em
              >
            </h3>
            <h4 class="text-right text-sm text-c_darkyellow">
              por Mitzi Gómez
            </h4>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/octubre/howToChooseTechnology.png"
                alt="How to Choose and Use Technology Tools for COIL with Data Privacy and
          Data Protection in Mind"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              El documento
              <strong
                >"How to Choose and Use Technology Tools for COIL with Data
                Privacy and Data Protection in Mind"</strong
              >
              (Mendez, 2023) (Cómo elegir y utilizar herramientas tecnológicas
              para COIL, teniendo en cuenta la privacidad y la protección de
              datos personales), publicado en inglés por la Florida
              International University (FIU), ofrece información sustancial
              sobre la selección y uso de herramientas tecnológicas para
              realizar cursos en la modalidad de Aprendizaje Internacional
              Colaborativo en Línea (COIL) enfocándose en la relevancia de la
              privacidad y protección de datos. Presenta estrategias prácticas,
              recomendaciones y elementos clave para que en nuestras
              instituciones de educación superior, podamos garantizar cursos
              COIL seguros e inclusivos.
            </p>
            <p>Dentro de las recomendaciones, se establecen las siguientes:</p>
            <ol>
              <li>
                <p>
                  <strong>Elegir herramientas tecnológicas adecuadas:</strong>
                </p>
                <ul>
                  <li>
                    Primero deben definirse los objetivos del COIL que deseamos
                    realizar, eso nos ayudará a comprender cuáles son las
                    herramientas más alineadas.
                  </li>
                  <li>
                    De las herramientas tecnológicas seleccionadas, se
                    recomienda evaluar qué tan accesible son para los usuarios,
                    funciones y sobre todo, identificar sus características de
                    seguridad y con qué otras herramientas es compatible.
                  </li>
                  <li>
                    Es importante usar plataformas que ambas instituciones
                    participantes del COIL conozcan.
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Diseño Universal para el Aprendizaje (DUA):</strong>
                </p>
                <ul>
                  <li>
                    Propone criterios de accesibilidad e inclusión para que las
                    herramientas puedan ser usadas por estudiantes que tengan
                    diversas capacidades y características.
                  </li>
                  <li>
                    Se enfoca en interfaces amigables, incluyendo modalidades
                    como texto, audio o video y otras opciones que permitan a
                    los estudiantes expresarse de maneras diversas para
                    aprender.
                  </li>
                </ul>
              </li>
              <li>
                <p><strong>Privacidad y protección de datos:</strong></p>
                <ul>
                  <li>
                    Detalla las diferencias entre privacidad de datos
                    (refiriéndose al control sobre el uso de información
                    personal) y protección de datos (descrito como la seguridad
                    con la que se cuenta para prevenir accesos no autorizados).
                  </li>
                  <li>
                    Enumera normativas relevantes como el
                    <em>Reglamento General de Protección de Datos</em> (GDPR) en
                    Europa y FERPA en Estados Unidos, destacando su aplicación
                    en contextos globales.
                  </li>
                </ul>
              </li>
              <li>
                <p><strong>Medidas de seguridad:</strong></p>
                <ul>
                  <li>
                    Sugiere adoptar prácticas en los COIL como el uso de
                    plataformas con cifrado, contraseñas seguras, autenticación
                    de múltiples factores y almacenamiento seguro de la
                    información.
                  </li>
                  <li>
                    Enfatiza la importancia de que solo las personas indicadas
                    puedan acceder a la recopilación de la información personal
                    de los participantes.
                  </li>
                </ul>
              </li>
              <li>
                <p><strong>Mejores prácticas</strong></p>
                <ul>
                  <li>
                    Hace referencia a la importancia de obtener consentimiento
                    explícito de los estudiantes, pero también de educarlos
                    sobre la importancia de proteger su privacidad y que se
                    sigan los procedimientos que se determinen para compartir
                    resultados de proyectos cuando se realizan en plataformas
                    públicas o congresos.
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong
                    >Políticas específicas de reuniones sincrónicas:</strong
                  >
                </p>
                <ul>
                  <li>
                    Proporciona elementos específicos que deben ser tomados en
                    cuenta para sesiones sincrónicas en los proyectos COIL,
                    considerando alternativas para estudiantes que no puedan
                    participar en los tiempos indicados.
                  </li>
                </ul>
              </li>
            </ol>
            <p><strong>Conclusiones</strong></p>
            <p>
              Este documento nos indica la importancia de trabajar en
              colaboración entre las instituciones participantes, para que se
              puedan definir objetivos claros, identificar las condiciones de
              seguridad de cada institución y normas en general que deban ser
              conocidas para su implementación, buscando además desarrollar
              proyectos COIL con un enfoque ético y responsable, equilibrando la
              innovación tecnológica con el cumplimiento de regulaciones locales
              e internacionales, garantizando al mismo tiempo una experiencia
              educativa inclusiva.
            </p>
          </article>
          <article>
            <h2>Testimonios</h2>
            <p>
              La Dra. Aurora Piñeiro organizó dos seminarios semestrales en la
              modalidad COIL en el marco de la Cátedra de Estudios Irlandeses de
              la Facultad de Filosofía y Letras: “Eavan Boland-Anne Enright” en
              coordinación con las Cátedras de Estudios Irlandeses de la
              Universidad de Sao Paulo, Brasil; la Universidad de la Pampa,
              Argentina y la Universidad del Salvador de Buenos Aires,
              Argentina. Estas son las 4 cátedras de estudios irlandeses en
              América Latina. En cuanto a la calendarización, se respetó el
              horario del semestre de la UNAM. El primer curso se dictó en el
              2022 y el segundo en el 2023. Las sesiones eran de dos horas.
            </p>
            <p>
              Los alumnos participantes fueron de varios Colegios de la
              Facultad, y de las universidades participantes, se tuvo una
              asistencia muy nutrida, hubo 80 personas inscritas y que
              asistieron de manera regular a las sesiones sincrónicas. En 2022
              se iniciaron estas cátedras en conmemoración del centenario de la
              independencia de Irlanda; en la primera ocasión se impartieron
              clases tanto en inglés como en español y en el 2023 se acordó que
              el curso completo sería dictado en inglés, ya que los estudiantes
              brasileños dijeron que les fue muy difícil entender las sesiones
              en español. Participaron cinco profesores de cada universidad que
              impartieron las sesiones. Los trabajos se aceptaron tanto en
              inglés como en español.
            </p>
            <p>
              La Dra. Piñeiro comenta que fue una experiencia sumamente
              enriquecedora y que los alumnos disfrutaron la interacción. Este
              es un COIL que se planeó sin saber que se estaba empleando esta
              metodología, una vez que se terminó la actividad y se socializó
              nos dimos cuenta que es un claro ejemplo de que la interacción
              entre académicos se da forma espontánea. Creemos firmemente que
              con una mayor difusión de la metodología las experiencias de
              trabajo internacional, que se daban de manera natural, serán aún
              más benéficas para los estudiantes y docentes.
            </p>
          </article>
          <article>
            <h2>Avisos de eventos</h2>
            <h3>Taller para coordinadores de la Red LATAM COIL</h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/agosto/workshop.jpg"
                alt="Visita del equipo COIL de la UNAH a la UNAM"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              Continúa abierto el proceso de registro al taller para
              coordinadores COIL de LATAM COIL. Lo habíamos anunciado en nuestro
              boletín anterior, lo reiteramos, quedan aún algunos días para
              registrarse a esta oportunidad de fortalecimiento de los
              aprendizajes en el área de coordinación COIL.
            </p>
            <p class="text-right">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfXK0P3ocNIAAogNCSRdSbDnZW9PIwIePc4SrC_-UMM9TFwLA/viewform"
                target="_blank"
                class="bg-c_lightyellow inline-block px-2"
                >Link de registro</a
              >
            </p>
            <h3>
              Intercambio Virtual COIL, por el Instituto de Liderazgo
              Estratégico de la Universidad de Minnesota
            </h3>
            <p>
              ¡Ya están abiertas las postulaciones para el Instituto de
              Liderazgo de Intercambio Virtual COIL UMN 2025!
            </p>
            <p>
              Inscríbase para una sesión informativa del Instituto en diciembre:
              <a href="http://z.umn.edu/Institute_Info" target="_blank"
                >z.umn.edu/Institute_Info</a
              >
            </p>
            <p>
              Esta es su oportunidad de unirse a una red global de exalumnos de
              61 instituciones que han utilizado el Instituto para ampliar sus
              iniciativas COIL. Durante seis semanas, lo ayudaremos a:
            </p>
            <ul>
              <li>
                Diseñar objetivos COIL alcanzables a corto y mediano plazo para
                el éxito de los estudiantes, el cuerpo docente y la institución.
              </li>
              <li>
                Desarrollar acciones prácticas y de desarrollo para lograr los
                objetivos deseados.
              </li>
              <li>
                Descubrir nuevas estrategias para el reclutamiento efectivo del
                cuerpo docente, la asignación de personal, el desarrollo
                profesional, la evaluación del aprendizaje de los estudiantes,
                la evaluación del programa y más.
              </li>
              <li>Crear una red de socios amplia y diversa.</li>
            </ul>
            <p>
              ¡Visite
              <a href="http://z.umn.edu/COILinstitute" target="_blank"
                >z.umn.edu/COILinstitute</a
              >
              para obtener más información y presentar su solicitud antes del 3
              de febrero de 2025!
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/octubre/minnesota-COIL.jpg"
                alt="How to Choose and Use Technology Tools for COIL with Data Privacy and
          Data Protection in Mind"
                class="mx-auto max-w-lg block"
              />
            </p>
          </article>
          <article>
            <h2>Noticias</h2>
            <h3>
              Estancia académica en México de becarios AMEXCID de Honduras
            </h3>
            <h4 class="text-right text-sm text-c_darkyellow">
              por Guadalupe Vadillo y Carlos Maza
            </h4>
            <p>
              Con el objetivo de analizar los resultados educativos y la
              experiencia de usuario del COIL Consejería breve centrada en
              soluciones que se llevó a cabo en octubre de 2023, para mejorarlo
              y rediseñarlo, un equipo de la Universidad Nacional Autónoma de
              Honduras (UNAH) trabajó por dos semanas con su contraparte
              mexicana de la Facultad de Estudios Superiores Iztacala UNM. Esto
              fue posible debido a la obtención del programa programa “Becas de
              proyectos COIL del Gobierno de México para Extranjeros” que
              coordina la Agencia Mexicana de Cooperación Internacional para el
              Desarrollo (AMEXCID). La Lic. Yesi González de la UNAH hizo la
              postulación que resultó seleccionada, y se selecionó, por sus
              características destacadas a tres estudiantes: Yannis Zaret
              Gisselle Díaz Ramírez, Graciela Velásquez y Jorge Miguel López
              Sabillón. Por México las docentes fueron las doctoras Anabel de la
              Rosa y Guadalupe Vadillo, así como las alumnas del COIL, ya
              egresadas de su carrera, Lic. Melissa Higuera y Lic. Brenda Díaz.
            </p>
            <p>
              A lo largo de dos semanas hubo siete sesiones de trabajo con
              estudiantes y dos solo entre maestras, incluyendo la participación
              en línea de otras tres docentes de UNAH que participaron en el
              COIL. Se describió la experiencia vivida tanto por estudiantes
              como por docentes y se identificaron fortalezas y áreas de
              oportunidad en las áreas académica, tecnológica y de gestión. En
              general, la aprenciación de la experiencia fue muy positiva, y al
              hacer el análisis minucioso se fueron encontrando detalles muy
              puntuales que permitirán un rediseño para mejorar la calidad y
              efectividad de esta experiencia educativa.
            </p>
            <p>
              Adicionalmente, a lo largo de esos días se tuvo una amplia agenda
              cultural, que permitió que nuestros invitados tuvieran una
              perspectiva de la riqueza natural, histórica, artística y
              arquitectónica de la Ciudad de México. También se incluyeron
              conferencias sobre temáticas educativas. A continuación se
              presenta la agenda completa:
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/octubre/agenda-honduras.png"
                alt="Estancia académica en México de becarios AMEXCID de Honduras"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              Agradecemos la nota de Carlos Maza que reseña el evento inaugural
              del 7 de noviembre, realizado en el Auditorio Pablo González
              Casanova de la CUAED:
            </p>
            <div class="pl-4">
              <p>
                El evento fue inaugurado por la Dra. Guadalupe Vadillo en
                representación de la titular de la Coordinación de Universidad
                Abierta y Educación Digital, Dra. Anabel de la Rosa, quien
                también participó como docente en la experiencia COIL con la
                UNAH; por la Mtra. Dolores González Casanova, Directora de
                Enlace Institucional en la Dirección General de Cooperación e
                Internacionalización de la UNAM; por la Lic. Yesi Iveth
                González, titular del equipo hondureño en México, y por la Lic.
                María Silvia Barquet Muñoz en representación de la AMEXCID.
              </p>
              <p>
                En la apertura se abordaron los aspectos generales de las
                colaboraciones COIL y los procesos que implican. La
                representante de AMEXCID se refirió a la intensa dinámica de
                trabajo que muestra la UNAM y al resultado patente de las
                colaboraciones impulsadas por medio de las metodologías COIL
                representado por la presencia del equipo hondureño en Ciudad
                Universitaria.
              </p>
              <p>
                Después de las intervenciones de las cuatro autoridades por la
                inauguración, la Dra. Vadillo, que a su vez coordina el trabajo
                de la Comisión COIL en la Red Universitaria de Responsables de
                Internacionalización, dio paso a la participación de las alumnas
                y alumno de ambos países, quienes describieron con gran claridad
                y de forma muy amena lo sucedido durante su colaboración.
              </p>
              <p>
                El evento concluyó en un agradable y amistoso convivio que
                permitió establecer nuevos contactos con las personas asistentes
                y hacer nuevos planes para futuras colaboraciones. Estuvieron
                presentes en la actividad integrantes de la comisión COIL de la
                RURI, docentes y académicos de diferentes entidades de la UNAM,
                funcionarios de AMEXCID y parte del equipo de la DGECI encargado
                de la promoción de las metodologías COIL en toda la UNAM.
              </p>
            </div>
            <p>
              El informe completo de esta estancia académica se encuentra en
              este
              <a
                href="docs/Informe_UNAH-UNAM_AMEXCID2024.pdf"
                download="Informe_UNAH-UNAM_AMEXCID2024.pdf"
                class="underline"
                >enlace</a
              >
              y el video testimonial está disponible en
              <a href="https://youtu.be/eA-j72lOsGU" target="_blank">YouTube</a
              >.
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/2024/octubre/informe-honduras.png"
                alt="Estancia académica en México de becarios AMEXCID de Honduras"
                class="mx-auto max-w-lg block"
              />
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "OctubreView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/octubre",
    };
  },
};
</script>
