<template>
  <div class="mt-6 grid gap-6 md:grid-cols-3 xl:grid-cols-4 p-10">
    <article
      v-for="person in people"
      :key="person.id"
      class="m-2 rounded shadow-md"
      v-bind:class="[`bg-alpha_${getColor()}`]"
      v-on:click="personDetails(person)"
    >
      <div class="relative">
        <div class="alpha rounded"></div>
        <div
          v-if="person.attributes.photo"
          class="h-72 bg-no-repeat bg-cover bg-center rounded"
          v-bind:style="{
            'background-image': `url(${getImgUrl(person.attributes.photo)})`,
          }"
        ></div>
        <div
          v-else
          class="h-72 bg-no-repeat bg-cover bg-center rounded"
          v-bind:style="{
            'background-image':
              'url(https://semanahddata.cuaieed.unam.mx/uploads/coil_unam_c9e37590c9.jpg)',
          }"
        ></div>
        <h3
          class="absolute rounded-b w-full bottom-0 right-0 p-2 text-right z-10"
        >
          {{ person.attributes.name }}
        </h3>
      </div>
    </article>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ComissionInfo",
  data() {
    return {
      people: [
        {
          id: 17,
          attributes: {
            name: "Adriana Estefany Carbajal Chávez",
            bio: "Es Internacionalista por la UNAM, cuenta con estudios de Maestría en Migración Internacional por El Colegio de la Frontera Norte; ha concluido diplomados nacionales e internacionales por la Universidad Complutense de Madrid, la Universidad Anáhuac Sur y la UNAM en temas educativos, de seguridad internacional y migración. En el rubro de internacionalización de la UNAM es miembro de la Red Universitaria de Responsables de Internacionalización desde su creación. Actualmente se desempeña como Jefa de Intercambio Académico y Vinculación y Responsable de Movilidad. Ha tenido la oportunidad de apoyar a más de 1300 alumnos y docentes de la Facultad en la realización de actividades nacionales e internacionales. Funge también como docente de la carrera de Relaciones Internacionales en la FES Aragón.",
            photo: "estefanyCarbajal.jpg",
          },
        },
        {
          id: 1,
          attributes: {
            name: "Caridad Rangel",
            bio: "Cirujana Dentista egresada de la FES Zaragoza, comprometida con la profesión, interesada en la docencia y en la investigación. Generadora de propuestas, responsable y con visión novedosa del proceso de enseñanza, convencida de la internacionalización como fuente de transformación en la educación.",
            photo: "caridadRangel.jpg",
          },
        },
        {
          id: 2,
          attributes: {
            name: "Carlos Maza",
            bio: "Licenciado en Sociología por la Universidad Iberoamericana con estudios de periodismo en la Facultad de Ciencias Políticas y Sociales de la UNAM. Editor y promotor de la lectura, vivió 20 años en el Perú. Profesor de metodología de la investigación, literatura, didáctica de la música y otras materias en universidades de México y Perú. Ha publicado cuentos, poesía y ensayos. Escribe sobre cine, música y edición en la blogosfera. Actualmente, como Coordinador de Fomento a la Internacionalización de la Dirección General de Cooperación e Internacionalización de la UNAM, impulsa la educación colaborativa internacional en línea y colabora en la edición de la revista _UNAM Internacional_.",
            photo: "carlosMaza.jpg",
          },
        },
        {
          id: 3,
          attributes: {
            name: "Dagny Valadez",
            bio: "Dagny Valadez es licenciada en Letras inglesas por la UNAM, maestra en Literatura Mexicana Contemporánea por la UAM y tiene estudios de especialidad en Género y Educación por la UPN. Tiene experiencia docente de más de 20 años en el área de enseñanza de lenguas.",
            photo: "dagnyValadez.jpg",
          },
        },
        {
          id: 4,
          attributes: {
            name: "Dolores González Casanova",
            bio: "Maestra en lingüística teórica por la Universidad de Essex, estudió Lingüística en la Escuela Nacional de Antropología e Historia. Editora, redactora y traductora. Subdirectora de Enlace Institucional en la Dirección General de Cooperación e Internacionalización de la UNAM, desde donde coordina la Red Universitaria de Responsables de Internacionalización (RURI), impulsa proyectos de colaboración internacional en línea y participa en el comité editorial de la revista _UNAM Internacional_.",
            photo: "doloresGonzalezCasanova.jpg",
          },
        },
        {
          id: 5,
          attributes: {
            name: "Erik Granados",
            bio: "Psicólogo egresado de la Universidad Autónoma de Yucatán (UADY), con estudios en educación y enseñanza de las lenguas en Bucknell University (PA, EUU). Actual secretario de Atención a la Comunidad (SAC) en la Escuela Nacional de Estudios Superiores (ENES) Unidad Mérida, ha sido profesor de lenguas en el CEPHCIS, la UMDI Sisal, y la ENES Mérida de la UNAM en Yucatán desde el 2007. Interesado en las tecnologías y su impacto en el aprendizaje, la promoción y la prevención de la salud, asuntos estudiantiles, y la internacionalización. Fan de Lego, el café y los perros.",
            photo: "erikGranados.jpg",
          },
        },
        {
          id: 6,
          attributes: {
            name: "Eréndira Peniche García",
            bio: "Responsable de Vinculación Institucional  e Internacionalización del Centro Peninsular en Humanidades y Ciencias Sociales de la UNAM en Mérida, Yucatán, donde labora desde el 2006.",
            photo: "erendiraPenicheGarcia.jpg",
          },
        },
        {
          id: 7,
          attributes: {
            name: "Gabriela Alfaro Vega",
            bio: "Desde 2008 y hasta la fecha se desempeña como Jefa del Departamento de Personal Académico y Movilidad Estudiantil de la Facultad de Ingeniería. Responsable operativa de Movilidad Estudiantil, en apoyo al Secretario General de la Facultad, quien funge como presidente de la Comisión de Movilidad Estudiantil, para coordinar las reuniones de esta Comisión. Coordina el proceso de participación de alumnos en el Programa de Movilidad Estudiantil de la Facultad de Ingeniería, así como en las convocatorias y programas de movilidad, así como de estudiantes que se reciben en la Facultad para realizar una estancia de movilidad, para lo cual desarrolló el Sistema de Movilidad Estudiantil de la Facultad de Ingeniería (SIMOVE), mismo que administra y actualiza para la gestión en línea de solicitudes de movilidad y de revalidación de asignaturas.",
            photo: "gabrielaAlfaroVega.jpg",
          },
        },
        {
          id: 8,
          attributes: {
            name: "Guadalupe Vadillo",
            bio: "Soy psicóloga, con master y doctorado en educación. Trabajo en la Coordinación de Universidad Abierta y Educación Digital, a cargo del Bachillerato a Distancia y de CUAED Internacional. Mi meta es lograr mejores y más disfrutables aprendizajes en línea ; )",
            photo: "guadalupeVadillo.jpg",
          },
        },
        {
          id: 9,
          attributes: {
            name: "Karen Castillo Acosta",
            bio: "Licenciada en derecho y criminología por la Universidad Autónoma de Tamaulipas y el Instituto de Ciencias y Estudios Superiores de Tamaulipas, respectivamente; Especialista en derecho del comercio exterior y maestranda en derecho por la División de Estudios de Posgrado de la Facultad de Derecho de la UNAM; actualmente, estudiante de la licenciatura en economía en la UNAM. Realizó una estancia en UCLA desarrollando el tema: “_The Hispanic Chamber of Commerce: A Case Study of Los Angeles_”. Académica en el Centro de Investigaciones sobre América Latina y el Caribe de la UNAM.",
            photo: "karenCastilloAcosta.jpg",
          },
        },
        /*{
          id: 10,
          attributes: {
            name: "Miriam Guillermina Gómez Casas",
            bio: "Licenciada en Derecho por la Facultad de Derecho de la UNAM. Maestría y especialidad en Derecho Corporativo en la Universidad Anáhuac Norte. Estancia de investigación en la Universidad Complutense de Madrid.\nResponsable de Investigación y Vinculación Académica en la Coordinación de Humanidades.\nPublicaciones: “Panorama General de las Políticas Fiscales para disminuir la Informalidad en México”, en “Estéticas y peluquerías de la Ciudad de México: entre la informalidad y la formalidad”; “Sujetos de la obligación tributaria”, en “Manual de Derecho Fiscal”; “Comentario al Artículo 31 Constitucional”, en “Derechos del pueblo mexicano: México a través de sus constituciones”; libro en coautoría “Derechos de las personas contribuyentes”.",
            photo: "miriamGuillerminaGomezCasas.jpg",
          },
        },*/
        {
          id: 11,
          attributes: {
            name: "Mitzi Gómez Morales",
            bio: "Secretaria de Cooperación Internacional de la Facultad de Contaduría y Administración de la UNAM, Coordinadora de Relaciones Internacionales de la Asociación Latinoamericana de Facultades y Escuelas de Contaduría y Administración ALAFEC. Es también Vicepresidenta del Consejo Ejecutivo del Instituto Mexicano de Ejecutivos en Finanzas Ciudad de México e Integrante de la Comisión Nacional de Certificación del mismo. Forma parte de la Federación Mexicana de Mujeres Universitarias. Mentora en el Programa de Empoderamiento de la Mujer PACEM de emprendimiento. Licenciada en Administración con Maestría en Negocios Internacionales. Coach Internacional Transformacional y facilitadora de Mindfulness.",
            photo: "mitziGomezMorales.jpg",
          },
        },
        {
          id: 12,
          attributes: {
            name: "Paola S. Mendieta Verdejo",
            bio: "Nació en la Ciudad de México el 20 de marzo de 1977. Cursó la Licenciatura en Pedagogía por el Colegio de Pedagogía de la Facultad de Filosofía y Letras con mención honorífica. Es maestra en Alta Dirección con especialidad en instituciones educativas de nivel medio superior y superior por la Facultad de Contaduría y Administración. Tiene experiencia en las áreas de evaluación educativa, planeación, educación especial, capacitación y desarrollo organizacional. Desde el 2018 se incorporó a la Coordinación de Relaciones y Asuntos Internacionales primero como Enlace y Vínculo con Europa, trabajó como Coordinadora de vinculación y evaluación con las sedes de la UNAM en el extranjero, donde se encargó de coordinar, consolidar, evaluar, analizar, promover y difundir las actividades académicas y culturales de las sedes, además de organizar y atender las visitas de socios estratégicos de la región de Medio Oriente y África y de las sedes. Actualmente se desempeña como Coordinadora de Fortalecimiento Académico en la Secretaría de Desarrollo Institucional (SDI).",
            photo: "paolaSMendietaVerdejo.jpg",
          },
        },
        {
          id: 13,
          attributes: {
            name: "Sandra Campos Araujo",
            bio: "Jefa de Sección Académica, Responsable de movilidad estudiantil y académica.\nEncargada de apoyar en los diferentes procesos que se deben seguir para realizar alguna\nmovilidad nacional o internacional por parte de alumnos y profesores.\nRepresentante RURI de la Escuela, ante la Red Universitaria de Responsables de\nInternacionalización.\nRepresentante CAMEL de la Escuela, ante el Comité Académico de Movilidad Estudiantil\nInternacional a Nivel Licenciatura (CAMEL)\nEnlace Escolar de la Escuela, ante el Instituto para el Desarrollo y Atención a las\nJuventudes del Estado de Guanajuato (JUVENTUDESGTO), apoyando en los diferentes\nprogramas de becas tanto económicas, en especie, así como de movilidad nacional e\ninternacional para alumnos y profesores.",
            photo: "sandraCamposAraujo.jpeg",
          },
        },
        {
          id: 14,
          attributes: {
            name: "Sandra Centeno",
            bio: "Egresada de la carrera de Química Farmacéutico Biológica de la Facultad de Química de la UNAM, y Maestra en Ciencias por la misma Entidad Académica. Profesora de asignatura de la Facultad de Química con 10 años de experiencia docente. Ha publicado libros, artículos científicos y colabora en la generación e implementación de nuevos guiones experimentales para la enseñanza de la Toxicología. Actualmente, como Coordinadora de Programas Académicos de Apoyo a la Licenciatura, busca consolidar y generar nuevas estrategias de movilidad e internacionalización de la Facultad de Química.",
            photo: "sandraCenteno.jpg",
          },
        },
        {
          id: 15,
          attributes: {
            name: "Sandra Lorenzano",
            bio: "Sandra Lorenzano es narradora, poeta y ensayista “argen-mex” (nació en Argentina, en 1960, y se exilió en México en 1976). Doctora en Letras por la UNAM, es profesora e investigadora en la misma universidad. Creadora Artística Honorífica del Sistema Nacional de Creadores de Arte, en 2022 fue elegida Presidenta de la Asamblea Consultiva del Consejo Nacional para Prevenir la Discriminación (CONAPRED). \nColabora regularmente en diversos medios de comunicación de México, América Latina y España, tanto en prensa escrita como en radio y televisión. Actualmente produce y conduce el programa “Violeta y oro” en Radio UNAM.\nHa coordinado, entre otras, las siguientes publicaciones: Alguien aquí que tiembla. Celebración poética de mujeres; Literatura y derechos humanos ; Poesía y violencia hoy y Aproximaciones a Sor Juana. \nEntre sus libros están Escrituras de sobrevivencia. Narrativa argentina y dictadura (Mención en el Premio Nacional de Ensayo Literario J. Revueltas), los poemarios Vestigios (Pre-Textos, España), Herencia (Vaso Roto, España / México) y Abismos, quise decir (en prensa), así como las novelas Saudades, Fuga en mí menor, La estirpe del silencio y El día que no fue (Alfaguara). Su obra forma parte de diversas antologías y ha sido traducida al inglés y al italiano.\nEn 2023 fue galardonada con el Premio Clemencia Isaura de Poesía.",
            photo: "sandraLorenzano.jpg",
          },
        },
        {
          id: 16,
          attributes: {
            name: "Susana Chávez",
            bio: "Doctora en Educación con más de 14 años de experiencia en vinculación profesional y laboral de universitarios, administración de personal académico y proyectos de planeación institucional. Ocupa el cargo de Enlace de Internacionalización de la Dirección General de Orientación y Atención Educativa (DGOAE).",
            photo: "susanaChavez.jpg",
          },
        },
      ],
      photoAlpha: [
        "gold",
        "lightblue",
        "darkblue",
        "darkmagenta",
        "lightmagenta",
        "darkorange",
        "lightorange",
        "darkyellow",
        "lightyellow",
        "yellow",
      ],
      personColor: "",
    };
  },
  methods: {
    personDetails(personDetails) {
      const personHtml = `
      <h2>${personDetails.attributes.name}</h2>
      <div class="text-left text-sm mt-2">
        <p>${personDetails.attributes.bio}</p>
      </div>`;
      Swal.fire({
        html: personHtml,
        confirmButtonText: "Continuar",
      });
    },
    getColor() {
      return this.photoAlpha[
        Math.trunc(Math.random() * this.photoAlpha.length)
      ];
    },
    getImgUrl: function (imagePath) {
      return require("@/assets/images/consejeros/" + imagePath);
    },
  },
};
</script>

<style scoped>
:root {
  --c-white: #f2f2f2;
  --c-gold: #e6a41c;
  --c-darkblue: #220f60;
  --c-lightblue: #424dc3;
  --c-darkmagenta: #bd0a68;
  --c-lightmagenta: #c758b6;
  --c-darkorange: #d32628;
  --c-lightorange: #f29047;
  --c-darkyellow: #dc860f;
  --c-lightyellow: #f7e940;
  --c-yellow: #f3c331;
}
article.bg-alpha_gold .alpha {
  background-color: rgba(217, 142, 4, 0.5);
}
article.bg-alpha_darkblue .alpha {
  background: rgba(3, 76, 140, 0.5);
}
article.bg-alpha_lightblue .alpha {
  background: rgba(61, 129, 217, 0.5);
}
article.bg-alpha_darkmagenta .alpha {
  background: rgba(189, 10, 105, 0.5);
}
article.bg-alpha_lightmagenta .alpha {
  background: rgba(199, 88, 182, 0.5);
}
article.bg-alpha_darkorange .alpha {
  background: rgba(211, 38, 41, 0.5);
}
article.bg-alpha_lightorange .alpha {
  background: rgba(242, 145, 71, 0.5);
}
article.bg-alpha_darkyellow .alpha {
  background: rgba(220, 135, 15, 0.5);
}
article.bg-alpha_lightyellow .alpha {
  background: rgba(247, 232, 64, 0.5);
}
article.bg-alpha_yellow .alpha {
  background: rgba(243, 195, 49, 0.5);
}
.alpha {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
article h3 {
  color: var(--c-white);
}
</style>
