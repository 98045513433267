<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <div class="col w-full">
          <router-link
            v-for="bulletin in bulletins"
            :key="bulletin.number"
            :to="{ name: bulletin.name }"
          >
            <div class="border border-gray-200 shadow-sm flex flex-row mb-6">
              <div class="w-1/3">
                <img
                  :src="getImgUrl(bulletin.cover)"
                  class="img-fluid"
                  :alt="bulletin.number"
                />
              </div>
              <div class="w-2/3 pl-10">
                <h4>{{ bulletin.number }}</h4>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </article>
  </section>
</template>

<style></style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "PreviosView",
  components: {
    HeaderCoil,
  },
  methods: {
    getImgUrl: function (imagePath) {
      return require("@/assets/images/coilaborando/" + imagePath);
    },
  },
  data() {
    return {
      title: "Números anteriores",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos",
      bulletins: [
        {
          number: "Número 15, octubre - noviembre de 2024",
          cover: "2024/octubre/cover.jpg",
          name: "2024-octubre",
        },
        {
          number: "Número 14, agosto - septiembre de 2024",
          cover: "2024/agosto/cover.jpg",
          name: "2024-agosto",
        },
        {
          number: "Número 13, junio - julio de 2024",
          cover: "2024/junio/cover.jpg",
          name: "2024-junio",
        },
        {
          number: "Número 12, mayo de 2024",
          cover: "2024/mayo/cover.jpg",
          name: "2024-mayo",
        },
        {
          number: "Número 11, abril de 2024",
          cover: "2024/abril/cover.jpg",
          name: "2024-abril",
        },
        {
          number: "Número 10, marzo de 2024",
          cover: "2024/marzo/cover.jpg",
          name: "2024-marzo",
        },
        {
          number: "Número 9, febrero de 2024",
          cover: "2024/febrero/cover.jpg",
          name: "2024-febrero",
        },
        {
          number: "Número 8, enero de 2024",
          cover: "2024/enero/cover.jpg",
          name: "2024-enero",
        },
        {
          number: "Número 7, diciembre de 2023",
          cover: "2023/diciembre.jpg",
          name: "2023-diciembre",
        },
        {
          number: "Número 5-6, octubre-noviembre de 2023",
          cover: "2023/octubre.jpg",
          name: "2023-octubre",
        },
        {
          number: "Número 4, septiembre de 2023",
          cover: "2023/septiembre.jpg",
          name: "2023-septiembre",
        },
        {
          number: "Número 3, agosto de 2023",
          cover: "2023/agosto.jpg",
          name: "2023-agosto",
        },
        {
          number: "Número 2, julio de 2023",
          cover: "2023/julio.jpg",
          name: "2023-julio",
        },
        {
          number: "Número 1, junio de 2023",
          cover: "2023/junio.jpg",
          name: "2023-junio",
        },
      ],
    };
  },
};
</script>
